<template>
  <div>
    <div class="d-flex">
      <nav id="breadcrumbs" class="mb-3">
        <ul>
          <li><a href="#"> <i class="uil-home-alt"></i> </a></li>
          <li>{{ this.$t('general.settings') }}</li>
        </ul>
      </nav>
    </div>
    <div uk-grid>

      <div class="uk-width-1-3@m uk-flex-last@m">
        <nav class="responsive-tab style-3 setting-menu card"
          uk-sticky="top:30 ; offset:100; media:@m ;bottom:true; animation: uk-animation-slide-top">
          <ul>
            <li :class="isActiveAccount ? 'uk-active' : ''"><a href="#" @click.prevent="changeIsActive(1)"><i
                  class="uil-hdd"></i> {{ $t('general.account_settings') }}</a></li>
            <li :class="isActiveUser ? 'uk-active' : ''"><a href="#" @click.prevent="changeIsActive(2)"><i
                  class="uil-user"></i> {{ $t('general.user_settings') }}</a></li>
            <li :class="isActiveAppearance ? 'uk-active' : ''"><a href="#" @click.prevent="changeIsActive(3)"><i
                  class="uil-paint-tool"></i>{{ $t('general.view_settings') }}</a></li>
          </ul>
        </nav>
      </div>

      <div class="uk-width-expand">

        <div v-if="errorMessages" class="uk-alert-danger" uk-alert>
          <a class="uk-alert-close" uk-close></a>
          <p style="color:red;">
            <i class="uil-exclamation-triangle"></i> {{ errorMessages }}
          </p>
        </div>
        <div v-if="successMessage" class="uk-alert-success" uk-alert>
          <a class="uk-alert-close" uk-close></a>
          <p style="color:green;">
            <i class="uil-exclamation-triangle"></i> {{ $t('general.update_success') }}
          </p>
        </div>
        <div class="card rounded" v-if="isActiveAccount">
          <div class="p-3 d-flex justify-content-between">
            <h4 class="mb-0"><i class="uil-hdd"></i> Hesap Ayarları</h4>
          </div>
          <hr class="m-0">
          <default-loading v-if="form_loading"></default-loading>
          <form v-else class="uk-child-width-2-2@s uk-grid-small p-4" uk-grid>
            <div class="form-group row domain">
              <div class="col-md-12">
                <label class="col-form-label">{{ this.$t('institution.domain_name') }}<span
                    class="required">*</span></label>
                <div class="d-flex">
                  <input type="text" class="form-control" id="domain" name="name" disabled placeholder=""
                    :value="item.alias"><span>.softinya.com</span>
                </div>
                <p class="note"><i class="uil-exclamation-octagon"></i> Sadece Super Admin düzenleyebilir.</p>
              </div>
            </div>

            <div class="form-group row domain">
              <div class="col-md-12">
                <label class="col-form-label">Puan Coin Oranı<span class="required">*</span></label>
                <div class="d-flex ">
                  <input type="text" class="form-control" id="point_coin_rate" name="point_coin_rate" placeholder=""
                    v-model="item.point_coin_rate"><span>puan = 1 coin</span>
                </div>
                <div class="uk-flex uk-flex-left">
                  <button class="btn btn-warning" type="button" @click="onClickResetPoints">Puanları Sıfırla</button>
                </div>
              </div>
            </div>

            <div class="form-group row domain">
              <div class="col-md-12">
                <label class="col-form-label">Beğenilen Eğitimler Listeleme Puanı <span class="required"></span></label>
                <select class="form-control" v-model="item.most_popular_education_rate"
                  name="most_popular_education_rate">
                  <option value="0">0 puan üzeri</option>
                  <option value="1">1 puan üzeri</option>
                  <option value="2">2 puan üzeri</option>
                  <option value="3">3 puan üzeri</option>
                  <option value="4">4 puan üzeri</option>
                  <option value="5">5 puan </option>
                </select>

              </div>
            </div>

            <div class="form-group row mb-3">
              <div class="col-md-12">
                <label class="col-form-label" for="logo">{{ $t('company.dashboard_bg_image') }}</label>

                <div class="position-relative d-flex justify-center justify-content-center">
                  <img v-if="item.search_bg_image && item.search_bg_image_link" style="height: 190px"
                    class="rounded img-fluid logoarea" :src="item.search_bg_image_link" alt="">
                  <img v-else style="height: 190px" class="rounded img-fluid logoarea"
                    src="@/assets/images/shutterstock_130027559.jpg" alt="">
                  <input type="file" class="d-none" id="avatar" @change="processFile($event)">
                  <button @click="inputClick()" type="button" class="btn btn-default btn-icon-label"
                    style="top: 50%;position: absolute;">
                    <span class="btn-inner--icon">
                      <i class="uil-image"></i>
                    </span>
                    <span class="btn-inner--text">{{ $t('general.image_change') }}</span>
                  </button>
                </div>
              </div>
            </div>

          </form>
          <div class="uk-flex uk-flex-right p-4">
            <button class="btn btn-default" @click="onSubmitSelf"> {{ $t("general.save") }}</button>
          </div>
        </div>

        <div class="card rounded" v-if="isActiveUser">
          <div class="p-3 d-flex justify-content-between">
            <h4 class="mb-0"><i class="uil-user"></i> Kullanıcı Ayarları</h4>
          </div>
          <hr class="m-0">
          <default-loading v-if="form_loading"></default-loading>
          <form v-else class="uk-child-width-2-2@s uk-grid-small p-4" uk-grid>



            <div class="form-group row">
              <div class="col-md-12">
                <label class="col-form-label">Kullanıcı Giriş Metodu<span class="required"></span></label>
                <select name="login_type" v-model="item.login_type_id">
                  <option v-for="(item, index) in login_types" :value="item.id" :key="index">{{ item.name }}</option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-12">
                <label class="col-form-label">Kullanıcı Doğrulama Metodu<span class="required"></span></label>
                <select name="authentication_type" v-model="item.authentication_type_id">
                  <option v-for="item in authentication_types" :value="item.id" :key="item.id">{{ item.name }}</option>
                </select>
              </div>
            </div>

            <div class="form-group list row">
              <div class="col-md-1 col-form-label">
                <input type="checkbox" v-model="item.password_change_required" name="" id="opt1">
              </div>
              <label class="col-md-9 col-form-label" for="opt1"> Kullanıcı İlk Giriş Yaptığında Parolasını
                Oluştursun</label>
            </div>
            <div class="form-group list row">
              <div class="col-md-1 col-form-label">
                <input type="checkbox" v-model="item.is_allowed_register_user" name="" id="opt2">
              </div>
              <label class="col-md-9 col-form-label" for="opt2"> Kullanıcılar Kendileri Üye Olabilsin</label>
            </div>

            <div class="form-group list row">
              <div class="col-md-1 col-form-label">
                <input type="checkbox" v-model="item.R" name="" id="opt3">
              </div>
              <label class="col-md-9 col-form-label" for="opt3"> Kullanıcı Giriş Metodunu Değiştirebilsin</label>
            </div>

            <div class="form-group list row">
              <div class="col-md-1 col-form-label">
                <input type="checkbox" v-model="item.is_allowed_authentication_type" name="" id="opt4">
              </div>
              <label class="col-md-9 col-form-label" for="opt4"> Kullanıcı Doğrulama Metodunu Değiştirebilsin </label>
            </div>
            <div class="form-group list row">
              <div class="col-md-1 col-form-label">
                <input type="checkbox" v-model="item.auto_training_assignment" name="" id="opt5">
              </div>
              <label class="col-md-9 col-form-label" for="opt5"> Kullanıcı Kaydolduğunda Katalog Eğitimleri Atansın
              </label>
            </div>
            <div class="form-group list row">
              <div class="col-md-1 col-form-label">
                <input type="checkbox" v-model="item.surname_view_restrict" name="" id="opt6">
              </div>
              <label class="col-md-9 col-form-label" for="opt6"> Kullanıların soyisimleri X**** şelinde gözüksün
              </label>
            </div>

          </form>
          <div class="uk-flex uk-flex-right p-4">
            <button class="btn btn-default" @click.prevent="onSubmit"> {{ $t("general.save") }}</button>
          </div>
        </div>
        <div class="card rounded" v-if="isActiveAppearance">

          <div class="p-3 d-flex justify-content-between">
            <h4 class="mb-0"><i class="uil-paint-tool"></i> Görünüm Ayarları</h4>
          </div>
          <hr class="m-0">
          <default-loading v-if="form_loading"></default-loading>
          <form v-else class="uk-child-width-2-2@s uk-grid-small p-4" uk-grid>

            <div class="form-group row">
              <div class="col-md-6">
                <label class="col-form-label">İkon Rengi<span class="required"></span></label>
                <!--color picker-->
                <input name=" Color Picker" type="color" v-model="iconColor" v-on:input="changeIconColor" />
              </div>
              <div class="col-md-6">
                <label class="col-form-label">Zemin Rengi<span class="required"></span></label>
                <!--color picker-->
                <input name=" Color Picker" type="color" v-model="backgroundColor" v-on:input="changeBackgroundColor" />

              </div>
            </div>
          </form>
          <div class="uk-flex uk-flex-right p-4">
            <button class="uk-button uk-button-outline mr-2" @click="resetColor">Ayarları Sıfırla</button>
            <button class="btn btn-default" @click.prevent="onSubmit('theme')"> {{ $t("general.save") }} </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import DataTable from "@/assets/components/dataTable/DataTable";
import DefaultLoading from "@/view/components/loading/DefaultLoading";
import module, {
  BASE_URL,
  GET_ITEM,
  UPDATE_ITEM,
  UPDATE_SELF,
  NEW_UPDATE_SELF,
  MODULE_NAME,
  ITEM, ERROR,
  RESET_POINTS
} from "@/core/services/store/company.module";
import store from "@/core/services";
import axios from "axios";
import Swal from "sweetalert2";
import {
  BASE_URL as TRAINING_CONTENT_BASE_URL,
  DELETE_ITEM as TRAINING_CONTENT_DELETE
} from "@/core/services/store/training_content.module";
import $ from "jquery";

export default {
  name: "CompanySettings",
  components: { DataTable, DefaultLoading },
  data() {
    return {
      // themeColor: '#3e416d',
      iconColor: '#93949c',
      backgroundColor: '#666CFF',
      successMessage: null,
      form_loading: false,
      isActiveAccount: true,
      isActiveUser: false,
      isActiveAppearance: false,
      member_status: null,
      login_types: [],
      authentication_types: []
    }
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule)
      }
    }
    registerStoreModule(MODULE_NAME, module)
  },
  computed: {
    item: {
      get() {
        return store.getters[MODULE_NAME + '/' + ITEM]
      },
      set(value) {
      }
    },
    errorMessages: {
      get() {
        return store.getters[MODULE_NAME + '/' + ERROR]
      },
      set(value) {
      }
    }
  },
  methods: {
    resetColor() {
      this.iconColor = '#93949c';
      const root = document.querySelector(':root');
      root.style.setProperty('--iconColor', this.iconColor);
      this.backgroundColor = '#666CFF'
      root.style.setProperty('--backgroundColor', this.backgroundColor);

    },
    changeIconColor() {
      const root = document.querySelector(':root');
      root.style.setProperty('--iconColor', this.iconColor);
    },
    changeBackgroundColor() {
      const root = document.querySelector(':root');
      root.style.setProperty('--backgroundColor', this.backgroundColor);
    },
    // changeFontColor() {
    //   const root = document.querySelector(':root');
    //   root.style.setProperty('--fontColor', this.fontColor);
    // },
    getAuthenticationTypes() {
      let types = []
      axios.get(`api/user/authentication-types`, {
        headers: this.apiHeaders
      }).then((result) => {
        result.data.forEach(data => {
          types.push({
            id: data.id,
            name: data.name
          })
        });
      })
      this.authentication_types = types
    },
    getLoginTypes() {
      let types = []
      axios.get(`api/user/login-types`, {
        headers: this.apiHeaders
      }).then((result) => {
        result.data.forEach(data => {
          types.push({
            id: data.id,
            name: data.name
          })
        });
      })
      this.login_types = types
    },
    changeIsActive(data) {
      if (data === 1) {
        this.isActiveAccount = true;
        this.isActiveUser = false;
        this.isActiveAppearance = false;
      }

      if (data === 2) {
        this.isActiveAccount = false;
        this.isActiveUser = true;
        this.isActiveAppearance = false;
      }

      if (data === 3) {
        this.isActiveAccount = false;
        this.isActiveUser = false;
        this.isActiveAppearance = true;
      }
      return false;
    },
    onSubmitSelf() {
      let self = this;
      self.form_loading = true
      this.successMessage = null;

      let form = this.item;

      let formData = new FormData();
      formData.append('point_coin_rate', form.point_coin_rate);
      formData.append('most_popular_education_rate', form.most_popular_education_rate);
      formData.append('search_bg_image', form.search_bg_image);
      formData.append('_method', 'PUT')

      self.$store.dispatch(MODULE_NAME + '/' + NEW_UPDATE_SELF, {
        url: BASE_URL + '/update-self/' + self.item.id,
        contents: formData,
      }).then(() => {
        if (!self.errorMessages) {
          self.successMessage = self.$t('profile.your_information_updated')
          self.form_loading = false
        }
      })
    },
    onClickResetPoints() {
      let self = this;
      Swal.fire({
        title: "Tüm kullanıcı puanları sıfırlanacak, devam etmek istediğinize emin misiniz?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Evet",
        cancelButtonText: "Vazgeç",
        confirmButtonColor: "#e31748",
      }).then((result) => {
        if (result.isConfirmed) {
          self.form_loading = true
          self.successMessage = null;
          store.dispatch(
            MODULE_NAME + "/" + RESET_POINTS,
            {
              contents: {
                'company_id': self.item.id,
              }
            }
          ).then(response => {
            if (!self.errorMessages) {
              self.successMessage = self.$t('profile.your_information_updated')
            }
            self.form_loading = false
          });
        }
      });
    },
    onSubmit(event = null) {
      if (event === 'theme') {
        let form = {
          "key": "theme_settings",
          "value": { "iconColor": this.iconColor, "backgroundColor": this.backgroundColor }
        }
        this.$store.dispatch(MODULE_NAME + '/' + UPDATE_SELF, {
          url: BASE_URL + '/' + (this.item.id ?? this.item.data.id) + '/settings',
          contents: form,
        }).then(() => {
          setTimeout(() => {
            if (!this.errorMessages) {
              this.successMessage = this.$t('profile.your_information_updated')
              this.form_loading = false
            }
          }, 500);
        })
      } else {
        this.successMessage = null;
        this.form_loading = true
        let form = {
          login_type_id: this.item.login_type_id,
          authentication_type_id: this.item.authentication_type_id,
          password_change_required: this.item.password_change_required ? 1 : 0,
          is_allowed_register_user: this.item.is_allowed_register_user ? 1 : 0,
          is_allowed_login_type: this.item.is_allowed_login_type ? 1 : 0,
          is_allowed_authentication_type: this.item.is_allowed_authentication_type ? 1 : 0,
          auto_training_assignment: this.item.auto_training_assignment ? 1 : 0,
          surname_view_restrict: this.item.surname_view_restrict ? 1 : 0,
          // iconColor: this.iconColor,
          // fontColor: this.fontColor
        }
        this.$store.dispatch(MODULE_NAME + '/' + UPDATE_SELF, {
          url: BASE_URL + '/update-settings/' + this.item.id,
          contents: form,
        }).then(() => {
          setTimeout(() => {
            if (!this.errorMessages) {
              this.successMessage = this.$t('profile.your_information_updated')
              this.form_loading = false
            }
          }, 500);
        })
      }

    },
    onClickEdit(id) {
      if (id !== this.parentMemberId) {
        let routeData = this.$router.resolve({ name: "EditUser", params: { id: id } });
        window.open(routeData.href, '_blank');
      }
    },
    processFile(event) {
      let imageInput = event.target.files[0];
      this.item.search_bg_image = imageInput;
      let blob = URL.createObjectURL(imageInput);
      let imageHolder = $('.logoarea');
      imageHolder.attr('src', blob);
    },
    inputClick() {
      $('#avatar').click();
    },
  },
  mounted() {
    this.$store.dispatch(MODULE_NAME + '/' + GET_ITEM, {
      url: BASE_URL + '/self'
    }).then((response) => {
      let settings = response?.settings.find(x => x.key === 'theme_settings');
      this.iconColor = settings?.value?.iconColor;
      this.backgroundColor = settings?.value?.backgroundColor;
    });
    this.getAuthenticationTypes()
    this.getLoginTypes()
  }
};
</script>
